import classNames from 'classnames';

import CornerArrow from '@hiredigital/ui/Icon/icons/cornerArrow.inline.svg';
import Styles from './Button.module.scss';

const Button = ({
  button = { url: '#', label: '' },
  isSecondary = false,
  onClick,
  className,
  ariaLabel,
}) => {
  return (
    button && (
      <a
        aria-label={ariaLabel}
        className={classNames(Styles.button, isSecondary ? Styles.secondary : '', className)}
        onClick={onClick}
        href={button.url}>
        {button.label}
        <CornerArrow alt='arrow' className={Styles.arrow} loading='lazy' />
      </a>
    )
  );
};

export default Button;
