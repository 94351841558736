import PropTypes from 'prop-types';
import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import InterceptorProvider from '@hiredigital/ui/InterceptorProvider';
import { request, authRequest, baseRequest } from '@apis/utils';

import { defaultHead } from './defaults.js';

import {
  getMetaContent,
  generateSchema,
  generateSocial,
  generateProd,
  generateDev,
  convertPropsToHeadElements,
} from './utils.jsx';

const BaseLayout = ({
  className,
  children,
  pageHead,
  removeMetaImage = false,
  removeMetaSocial = false,
  removeMetaJsonLd = false,
  canonicalUrl: initialCanonical,
}) => {
  const router = useRouter();
  var currentPath = router.asPath.split('?')[0];
  const pageUrl = process.env.NEXT_PUBLIC_SITE_URL + currentPath;
  const canonicalUrl = initialCanonical || process.env.NEXT_PUBLIC_CANONICAL_URL + currentPath;
  // const data = useLayoutQuery();
  // Note that the only one cheat we have is to allow a single image meta but it does not exist
  const commonMeta = {
    title: (pageHead && pageHead.title) || defaultHead.title,
    ...(!removeMetaImage && {
      image: getMetaContent(pageHead, 'image') || getMetaContent(defaultHead, 'image'),
    }),
    description:
      getMetaContent(pageHead, 'description') || getMetaContent(defaultHead, 'description'),
    url: pageUrl,
  };

  const socialHead = generateSocial(commonMeta, false);
  const envHead = process.env.NEXT_PUBLIC_ENV === 'prod' ? generateProd() : generateDev();
  const schemaData = generateSchema(commonMeta, false);
  const helmetDefaultHead = {
    ...defaultHead,
    ...(removeMetaImage
      ? { meta: defaultHead.meta.filter((meta) => meta.name !== 'image') }
      : { meta: [...defaultHead.meta] }),
  };

  return (
    <InterceptorProvider requests={[request, authRequest, baseRequest]}>
      <Head>
        {convertPropsToHeadElements(helmetDefaultHead)}
        {convertPropsToHeadElements(pageHead)}
        {!removeMetaSocial && convertPropsToHeadElements(socialHead)}
        {convertPropsToHeadElements(envHead)}
        <link rel='canonical' href={canonicalUrl} />
        {!removeMetaJsonLd && (
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: schemaData }}></script>
        )}
      </Head>
      {className ? <div className={className}>{children}</div> : children}
    </InterceptorProvider>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
  pageHead: PropTypes.object,
  removeMetaImage: PropTypes.bool,
  removeMetaSocial: PropTypes.bool,
  removeMetaJsonLd: PropTypes.bool,
};

BaseLayout.defaultProps = {
  removeMetaImage: false,
  removeMetaSocial: false,
  removeMetaJsonLd: false,
};

export default BaseLayout;
