import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { toSentenceCase } from '@hiredigital/lib/helpers/utils';

const InterceptorProvider = ({
  requests = [],
  onAuthorizationError,
  onNetworkError,
  onNetworkSuccess,
  children,
}) => {
  useEffect(() => {
    attach(requests);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeSentence = (curKey, description) => {
    const name = ['non_field_errors', 'nonFieldErrors'].includes(curKey)
      ? ''
      : `${toSentenceCase(curKey)}: `;
    return `${name}${description}`;
  };

  const reduce = (error) => {
    if (typeof error === 'object') {
      return Object.keys(error)
        .reduce((prev, curKey) => [...prev, makeSentence(curKey, error[curKey])], [])
        .join(' ');
    }
    return error;
  };

  // simplify the error description and the index/prop of an object or array into one sentence
  const simplify = (errors) => {
    if (typeof errors !== 'object') return [errors];
    return Object.keys(errors).reduce((prev, curKey) => {
      const error = errors[curKey];

      if (Array.isArray(error)) {
        // const name = toSentenceCase(curKey !== 'non_field_errors' ? `${curKey}` : '');
        return error.map((v) => makeSentence(curKey, v));
      }

      return [...prev, reduce(error)];
    }, []);
  };

  // transform the error into array of string errors
  const transform = (error) => {
    const data = error?.response?.data;

    const err = 'There was an error with your request. Please try again.';

    if (!data) {
      return [err];
    }

    if (typeof data.detail == 'string') {
      return [data.detail];
    }

    if (typeof data == 'string') {
      if (data.length > 100) return [err];
      else return [data];
    }

    // Special handling for Wise API errors
    const errors = data.errors;
    if (Array.isArray(errors) && errors.length) {
      const wiseErrors = [];
      let isCurrency = false;
      let isType = false;
      errors.forEach((err) => {
        err?.message && wiseErrors.push(err.message);
        isCurrency = isCurrency || 'currency' === err?.path;
        isType = isType || 'type' === err?.path;
      });
      if (isCurrency) return ['Please select a currency.'];
      if (isType) return ["Please select a 'Pay to' option."];
      return wiseErrors;
    }

    const values = simplify(data);
    if (values.length > 100) {
      return [err];
    }

    return values;
  };

  const attach = (https) => {
    https.forEach((item) => {
      item.interceptors.response.handlers = [];
      item.interceptors.response.use(
        (response) => {
          onNetworkSuccess?.();
          return response;
        },
        (error) => {
          if (!error?.response?.status) {
            onNetworkError?.();
            return;
          }

          if (!axios.isCancel(error)) {
            transform(error).forEach((v) => toast.error(v, { id: v }));
          }

          if (error?.response?.status === 401) {
            // authorization error
            if (onAuthorizationError) {
              // in some cases, we need to redirect to external url
              // for instance the login page of frontend app is located in the marketing site
              onAuthorizationError();
            } else {
              // just return to the home page if no handler provided when authorization error occurs
              window.location.href = '/';
            }
            return;
          }

          return Promise.reject(error);
        }
      );
    });
  };

  return (
    <React.Fragment>
      <Toaster />
      {children}
    </React.Fragment>
  );
};

InterceptorProvider.propTypes = {
  children: PropTypes.node,
  requests: PropTypes.array,
  onAuthorizationError: PropTypes.func,
};

export default InterceptorProvider;
