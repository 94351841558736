export const defaultHead = {
  title: `Poly`,
  meta: [
    {
      name: 'image',
      content: 'https://media.graphassets.com/KHdcQPVVQriSFc4v9xQN',
    },
    {
      name: 'description',
      content: `Poly is a leading provider of in-housing, staffing, and digital solutions to Fortune 100 and other fast-growing companies.`,
    },
  ],
  link: [],
};
